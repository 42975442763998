/* RemoveMember.css */
.table-container {
  margin: 20px;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.greenHeaders {
  background-color: #4caf50;
  color: white;
  text-align: left;
  padding: 8px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tr:hover {
  background-color: #ddd;
}

.btn {
  cursor: pointer;
}

.btn-danger {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.btn-danger:hover {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
}

/* ActiveMembers.css */

.table-container {
  width: 68vw;
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead {
  background-color: white;
}

.table thead th {
  font-weight: bold;
  background-color: #f8f9fa;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: green; /* Light gray background on hover */
}

.greenHeaders {
  font-weight: bold;
  background-color: #dff0d8; /* Adjust if needed */
}

/* ActiveMembers.css */

.table-container {
  width: 68vw;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table thead th {
  font-weight: bold;
  background-color: #f8f9fa;
  padding: 8px;
}

.table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table tbody td,
.table tbody th {
  padding: 8px;
}

.table tbody tr:hover {
  background-color: green; /* Light gray background on hover */
}

.greenHeaders {
  font-weight: bold;
  background-color: #dff0d8; /* Adjust if needed */
}

/* styles.css */
.dashboard-container {
  padding: 20px;
}

.dashboard-title {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  background-color: #fffffa;
  box-shadow: 0px 2px 8px gray;
  border: none;
}

.dashboard-carded {
  background-color: #bdbdc6;
}

.gauge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.gauge-chart__text {
  fill: gray !important;
}

.main {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-grow: 1;
}

.pic {
  width: 55%;
  height: 100dvh;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 45%;
}

.picture {
  height: 100dvh;
  width: 100%;
}

.formcontent {
  width: 60%;
}
